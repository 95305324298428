.modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 100;
  padding: 3px 20px;
}

.modalContent {
  background-color: white;
  width: 80%;
  padding: 20px;
  border-radius: 5px;
}
.modalTitle {
  padding: 0.8rem;
  font-size: 1.5rem;
  border-bottom: 1px solid #aaa;
  text-align: center;
}
.modalBody {
  padding: 1rem;
}
.modalFooter {
  border-top: 1px solid #aaa;
  padding: 0.8rem;
  display: flex;
  justify-content: flex-end;
}
.modalFooter button {
  cursor: pointer;
}

.dummySearchWrapper {
  position: relative;
  min-width: 200px;
}

.dummySearchBlocker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.mobileSearch {
  display: none;
}


@media (max-width: 1080px) {
  .dummySearchWrapper {
    display: none;
  }
  .mobileSearch{
    display: flex;
  }
  .modal {
    padding: 3px 0;
    width: 100%; 
  }
  .modalContent {
    width: 100%;
    border-radius: 0;
  }
}
