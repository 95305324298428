
  .HoverCardContent {
    border-radius: 6px;
    padding: 20px;
    min-width: 150px;
    background-color: white;
    box-shadow: hsl(206 22% 7% / 55%) 0px 10px 38px -10px, hsl(206 22% 7% / 30%) 0px 10px 20px -15px;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
  }
  .HoverCardContent[data-side='top'] {
    animation-name: slideDownAndFade;
  }
  .HoverCardContent[data-side='right'] {
    animation-name: slideLeftAndFade;
  }
  .HoverCardContent[data-side='bottom'] {
    animation-name: slideUpAndFade;
  }
  .HoverCardContent[data-side='left'] {
    animation-name: slideRightAndFade;
  }

  .HoverCardArrow {
    fill: white;
  }


  @keyframes slideUpAndFade {
    0% {
      opacity: 0;
      transform: translateY(2px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideRightAndFade {
    0% {
      opacity: 0;
      transform: translateX(-2px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideDownAndFade {
    0% {
      opacity: 0;
      transform: translateY(-2px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideLeftAndFade {
    0% {
      opacity: 0;
      transform: translateX(2px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }