.header {
  display: flex;
  flex-direction: column;
  position: relative;
  background: var(--color-secondary-lightGray1);
  z-index: 2;
  border-bottom: 1px solid var(--color-secondary-lightGray3);
  color: var(--color-primary-gray);
}

@media (min-width: 1024px) {
  .sticky {
    position: fixed;
    width: 100%;
    z-index: 99;
    top: 0;
    box-shadow: 0 -6px 10px 15px rgba(0, 0, 0, 0.02);
  }
}

.header svg {
  display: block;
}

.contextMenu {
  @media (max-width: 1024px) {
    flex: 0;
  }
}

@media (max-width: 1024px) {
  .header {
    z-index: 3;
  }
}
